import axios from 'axios';

const getFormUrl = () => {
  const endpoint = window.REACT_APP_ENDPOINT;
  const [formType, id] = window.location.pathname.split('/').filter(value => value !== '');

  return `${endpoint}${id}/${formType}`
}

export const formGet = () => {
  const url = getFormUrl();
  const request = axios.get(url);
  let formResponse = null;
  let phoneField = false;

  return request
  .then((response) => {
    formResponse = response.data;
    phoneField = hasPhoneField(formResponse);
    if (phoneField) return axios.get(url + '/phones/types')
    return Promise.resolve(formResponse);
  })
  .then((phoneTypes) => {
    if (phoneField) {
      formResponse = {...formResponse, PhoneTypes: [...phoneTypes.data]}
    } else {
      formResponse = {...formResponse}
    }
    return Promise.resolve(formResponse)
  })
  .catch(console.log)
}

const hasPhoneField = (data) => {
  const foundField = data.Fields.find(f => f.Field.SecondaryEntityId === 28);
  return foundField;
}

export const formGetColor = () => {
  const url = getFormUrl()+'?$select=Color';
  const request = axios.get(url);

  request
  .then(response => response)
  .catch(e => console.log)
  .finally();

  return request;
}

export const formPost = (data) => {
  const url = getFormUrl();
  const request = axios.post(url, data);

  request
  .then(response => response)
  .catch(e => console.log)
  .finally();

  return request;
}

export const getCities = (search, page) => {
  const url = `${getFormUrl()}/cities?skip=${(page-1) * 10}&top=${10}&startswith=${search}`;

  return fetch(url);
}