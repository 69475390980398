import React from 'react';
import './loadingDots.css';

export const LoadingDots = () => {
  return (
    <div className="loading-dots">
      <div className="loading-dots__dot"></div>
      <div className="loading-dots__dot"></div>
      <div className="loading-dots__dot"></div>
    </div>
  )
}