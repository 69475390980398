import React, { useEffect, useState } from 'react';
import invert from 'invert-color';
import { formGetColor } from '../../utils/service';
import './loading.css';

export const Loading = props => {
  const {
    active,
    setCanLoadForm,
    isIframe,
  } = props;

  useEffect(() => {
    setCanLoadForm(true);
    // formGetColor()
    // .then(response => {
    //   setBgColor(response.data.Color ? response.data.Color : '#ffffff');
    // })
    // .catch(e => {

    // })
    // .finally(() => {

    // });
  }, []);

  const [bgColor, setBgColor] = useState('#ffffff');

  return (
    <div className={`loading ${active ? '--active' : ''}`} style={{backgroundColor: !isIframe ? bgColor : 'transparent'}}>
      <div className='loading-container'>
        <svg className="loading-svg" viewBox="25 25 50 50">
          <circle className="loading-svg-path" stroke={invert(bgColor, {black: '#aaa', white: '#eee'})} cx="50" cy="50" r="20" fill="none" strokeWidth="1" strokeMiterlimit="10" />
        </svg>
      </div>
    </div>
  )
}