import React from 'react';
import './ploomesFloat.css';

export const PloomesFloat = () => {
  return (
      <a /*
        className='ploomes-float'
          href='https://agrisk.agr.br/'
        target='_blank'
        rel='noopener noreferrer'>
          Powered by <img
              className='ploomes-float__logo'
              src='/assets/pbpr.png'
              alt='ploomes' height="20px" /*/>
     </a>
  )
}