import React from 'react';
import PropTypes from 'prop-types';
import './inputTextArea.css';

export const InputTextArea = props => {
  const {
    value,
    setValue,
    field,
    index,
  } = props;

  const handleInputChange = (input) => {
    setValue(input.target.value, index, field.ObjectKey, field.Required);
  }

  return (
    <textarea
      className='form-content__textarea'
      onChange={handleInputChange}
      value={value}
      name={field.FieldKey}
      required={field.Required}
    />
  );
}

InputTextArea.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}