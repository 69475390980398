import { getLocaleSeparator } from "./locale";

export const formatField = (ploomesField, value) => {
  // Simple text or multiline text
  if (ploomesField?.Field?.TypeId === 1 || ploomesField?.Field?.TypeId === 2) {
    return String(value).trim();
  }

  if (ploomesField.Field.Key.includes('_latitude') || ploomesField.Field.Key.includes('_longitude')) {
    return value;
  }

  // Amount, Decimal or Percentual
  if (ploomesField?.Field?.TypeId === 5 || ploomesField?.Field?.TypeId === 6 || ploomesField?.Field?.TypeId === 13) {
    const separator = getLocaleSeparator();
    const amountRegexString = "[^\\d\\" + separator + "]";
    const amountRegex = new RegExp(amountRegexString, "g");
    const amountNumber = value.replace(amountRegex, "");
    return parseFloat(amountNumber.replaceAll(separator, '.')).toString();
  }

  return value;
}