import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import './inputFormattedPhone.css';

export const InputFormattedPhone = props => {
  const {
    setValue,
    field,
    index,
    validation,
    phoneTypes,
  } = props;

  const maskOptions = [
    {
      pattern: '(##) #####-####',
      label: '(99) 99999-9999',
      img: '/assets/br.png',
      countryId: 76,
    },
    {
      pattern: null,
      label: 'Sem máscara',
      img: null,
      countryId: null,
    },
  ]

  const DEFAULT_INPUT_VALUES = { mask: maskOptions[0], invalid: false, maskDropdown: false, type: phoneTypes[0], typeDropdown: false };
  const DEFAULT_PHONE_VALUES = {phone: '', mask: maskOptions[0].countryId, type: phoneTypes[0].Id};
  const [invalidInput, setInvalidInput] = useState(false);
  const [valueArray, setValueArray] = useState([{ ...DEFAULT_PHONE_VALUES}]);
  const [phoneArray, setPhoneArray] = useState([{ ...DEFAULT_INPUT_VALUES }]);

  useEffect(() => {
    const tempPhoneArray = [...phoneArray];
    if (validation) {
      valueArray.forEach((value, index) => {
        if (tempPhoneArray[index] !== undefined && tempPhoneArray[index] !== null) {
          tempPhoneArray[index] = !validation(value);
        } else {
          tempPhoneArray.push(!validation(value))
        }
      })
      setPhoneArray([...tempPhoneArray]);
    }

    const isValid = !tempPhoneArray.find(v => v.invalid);
    setInvalidInput(!isValid);
    setValue(!isValid ? null : valueArray, index, field.ObjectKey, field.Required, isValid);

  }, [valueArray]);

  const handleInputChange = useCallback(index => (e) => {
    const tempValueArray = [...valueArray];
    const strippedValue = e.target?.value || '';
    if (tempValueArray[index] !== undefined && tempValueArray[index] !== null) {
      tempValueArray[index].phone = strippedValue;
    } else {
      tempValueArray.push({...DEFAULT_PHONE_VALUES, phone: strippedValue})
    }
    setValueArray([...tempValueArray]);
  }, [valueArray])

  const addToInputArray = useCallback(() => {
    const tempPhoneArray = [{ ...DEFAULT_INPUT_VALUES }, ...phoneArray];
    const tempValueArray = [{...DEFAULT_PHONE_VALUES}, ...valueArray];
    setPhoneArray([...tempPhoneArray]);
    setValueArray([...tempValueArray]);
  }, [phoneArray, valueArray])

  const removeFromInputArray = useCallback(index => (e) => {
    const tempPhoneArray = [...phoneArray.filter((v, i) => i !== index)];
    const tempValueArray = [...valueArray.filter((v, i) => i !== index)];
    setPhoneArray([...tempPhoneArray]);
    setValueArray([...tempValueArray]);
  }, [phoneArray, valueArray])

  const setMaskForPhone = useCallback((index, mask) => (e) => {
    const tempPhoneArray = [...phoneArray];
    const tempValueArray = [...valueArray];
    tempPhoneArray[index].mask = mask;
    tempValueArray[index].mask = mask.countryId;
    setPhoneArray([...tempPhoneArray]);
    setValueArray([...tempValueArray]);
  }, [phoneArray])

  const setTypeForPhone = useCallback((index, type) => (e) => {
    const tempPhoneArray = [...phoneArray];
    const tempValueArray = [...valueArray];
    tempPhoneArray[index].type = type;
    tempValueArray[index].type = type.Id;
    setPhoneArray([...tempPhoneArray]);
    setValueArray([...tempValueArray]);
  }, [phoneArray])

  const toggleMaskDropdown = useCallback((index) => (e) => {
    const tempPhoneArray = [...phoneArray];
    tempPhoneArray[index].maskDropdown = !tempPhoneArray[index].maskDropdown;
    setPhoneArray([...tempPhoneArray]);
  }, [phoneArray])

  const toggleTypeDropdown = useCallback((index) => (e) => {
    const tempPhoneArray = [...phoneArray];
    tempPhoneArray[index].typeDropdown = !tempPhoneArray[index].typeDropdown;
    setPhoneArray([...tempPhoneArray]);
  }, [phoneArray])


  return (
    <div className='form-content__phone-wrapper'>
      {invalidInput && <div className='form-content__validation'>O  valor digitado não é válido</div>}
      {phoneArray.map((input, index) => (
        <div className='form-content__phone-row' key={index}>
          <div className='form-content__phone-selector --left' onClick={toggleMaskDropdown(index)}>
          {input.mask?.img
            ? <img src={input.mask.img} alt='' />
            : <div className='form-content__phone-selector__blank'></div>}
            {input.maskDropdown && <div className='form-content__phone-selector__dropdown'>
              <ul>
                {maskOptions.map(mask => (
                  <li onClick={setMaskForPhone(index, mask)}>
                    {mask.img
                      ? <img src={mask.img} alt='' />
                      : <div className='form-content__phone-selector__blank'></div>}
                    {mask.label}
                  </li>
                ))}
              </ul>
            </div>}
          </div>
          <NumberFormat
            className={`form-content__input ${input.invalid ? '--invalid' : ''}`}
            onChange={handleInputChange(index)}
            value={valueArray[index].phone}
            name={field.FieldKey}
            type='tel'
            format={input.mask?.pattern}
          />
          <div className='form-content__phone-selector --right' onClick={toggleTypeDropdown(index)}>
          {input.type?.Name && <div className='form-content__phone-type'>{input.type?.Name}</div>}
            {input.typeDropdown && <div className='form-content__phone-selector__dropdown'>
              <ul>
                {phoneTypes.map(type => (
                  <li onClick={setTypeForPhone(index, type)}>
                    {type.Name}
                  </li>
                ))}
              </ul>
            </div>}
          </div>
          <div className='form-content__phone-buttons'>
            {(phoneArray.length > 1) && <div className="form-content__phone-btn --minus" onClick={removeFromInputArray(index)}> - </div>}
            {(index === 0) && <div className="form-content__phone-btn" onClick={() => addToInputArray()}> + </div>}
          </div>
        </div>
      ))
      }
    </div>
  );
}

InputFormattedPhone.propTypes = {
  value: PropTypes.array,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  validation: PropTypes.func,
  phoneTypes: PropTypes.array
}