export const validateCpf = (cpf) => {
  if (!cpf) return true;
  cpf = cpf.replace(/[^\d]+/g, '');
  const r = /^(0{11}|1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11})$/;
  if (!cpf || cpf.length !== 11 || r.test(cpf)) {
    return false;
  }
  function validateDigit(digit) {
    let add = 0;
    const init = digit - 9;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i + init), 10) * (i + 1);
    }
    return (add % 11) % 10 === parseInt(cpf.charAt(digit), 10);
  }
  return validateDigit(9) && validateDigit(10);
}