export const validateCnpj = (cnpj) => {
  if (!cnpj) return true;
  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  cnpj = cnpj.replace(/[^\d]/g, '');

  const r = /^(0{14}|1{14}|2{14}|3{14}|4{14}|5{14}|6{14}|7{14}|8{14}|9{14})$/;
  if (!cnpj || cnpj.length !== 14 || r.test(cnpj)) {
    return false;
  }
  cnpj = cnpj.split('');

  let i;
  let n;

  for (i = 0, n = 0; i < 12; i++) {
    n += cnpj[i] * b[i + 1];
  }
  n = 11 - (n % 11);
  n = n >= 10 ? 0 : n;

  if (parseInt(cnpj[12], 10) !== n) {
    return false;
  }

  for (i = 0, n = 0; i <= 12; i++) {
    n += cnpj[i] * b[i];
  }

  n = 11 - (n % 11);
  n = n >= 10 ? 0 : n;

  if (parseInt(cnpj[13], 10) !== n) {
    return false;
  }
  return true;
}