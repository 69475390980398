import React, { useEffect, useState, useCallback } from 'react';
import { InputSelect } from '../InputTypes/InputSelect/InputSelect';
import { checkEmptyFieldValue, getInputType, formatField } from '../../utils';
import { LoadingDots } from '../LoadingDots/LoadingDots';
import './formContent.css';
import { object } from 'prop-types';

export const FormContent = props => {
    const {
        fields,
        handleFormPost,
        loadingFormSend,
        formSent,
        setShowToaster,
        setToasterMessage,
        setToasterType,
        isIframe,
        phoneTypes
    } = props

    const [formPayload, setFormPayload] = useState([]);
    const [errorOnForm, setErrorOnForm] = useState(false);
    const [isFieldContact, setIsFieldContact] = useState(false);
    const [ordinatedFields, setOrdinatedFields] = useState(fields);
    const [hasRequired, setHasRequired] = useState(false);
    const [contactType, setContactType] = useState(0);

    useEffect(() => {
        const tempOrdinatedFields = [...fields];

        if (tempOrdinatedFields) {
            tempOrdinatedFields.sort((a, b) => {
                if (a.Ordination > b.Ordination) {
                    return 1;
                }
                if (a.Ordination < b.Ordination) {
                    return -1;
                }
                return 0;
            });
        }

        setOrdinatedFields([...tempOrdinatedFields]);
        setIsFieldContact(tempOrdinatedFields.filter(obj => obj.ImportationTemplateEntityId === 1 || obj.ImportationTemplateEntityId === 2).length > 0);

    }, [fields]);

    useEffect(() => {
        const formPayload = buildFormPayload(ordinatedFields);
        setFormPayload(formPayload);
    }, [ordinatedFields])

    const handleShowErrorToaster = () => {
        setShowToaster(true);
        setToasterMessage('Há erros no formulário');
        setToasterType('ERROR');

        setTimeout(() => {
            setShowToaster(false);
        }, 5000);
    }

    const buildFormPayload = fields => {
        const tempFormPayload = [];
        let hasOneRequired = false;
        fields.forEach(field => {
            const payloadObject = {};
            payloadObject[field.ObjectKey] = '';
            payloadObject.required = field.Required;
            tempFormPayload.push(payloadObject);
            if (field.Required) hasOneRequired = true;
        });

        setHasRequired(hasOneRequired);

        return [...tempFormPayload];
    }

    const onInputChange = useCallback((value, index, key, required, isValid) => {
        const payloadItem = {};
        payloadItem[key] = value;
        payloadItem.required = required;
        if (typeof isValid === 'boolean') {
            payloadItem.isValid = isValid;
        }
        setFormPayload([
            ...formPayload.slice(0, index),
            { ...payloadItem },
            ...formPayload.slice(index + 1),
        ])
    }, [formPayload]);

    const onTypeInputChange = (value, index, key, required, isValid) => {
        
        if (value) {
            if (value == 'PJ') {
                setContactType(1)
            } else {
                setContactType(2)
            }
        }else{
            setContactType(0)
        }

    };

    const onClickSubmit = (e) => {

        

        e.preventDefault();
        let hasErrorOnForm = false;

        if (contactType == 0 && isFieldContact) {
            handleShowErrorToaster();
            setErrorOnForm(true);
            return hasErrorOnForm = true;
        }

        let data = {};
        formPayload.forEach(field => {
            
            const newField = { ...field };
            const objKey = Object.keys(newField)[0];
            const ploomesField = ordinatedFields.find(ordinatedField => ordinatedField.ObjectKey === objKey);
            newField[objKey] = formatField(ploomesField, newField[objKey])
            const emptyField = checkEmptyFieldValue(newField[objKey]);
            const isRequired = emptyField && newField.required;
            const isInvalid = newField.hasOwnProperty('isValid') && !newField.isValid;

            const mustShowField = (ploomesField.ImportationTemplateEntityId != 1 && ploomesField.ImportationTemplateEntityId != 2) ? true : ploomesField.mustShowField;
            let hasErrorInField = (isRequired && mustShowField) || isInvalid;
            
            if (hasErrorInField) {
                handleShowErrorToaster();
                return hasErrorOnForm = true;
            }

            delete newField.required;
            delete newField.isValid;
            if (newField[objKey] === '') newField[objKey] = null;
            data = { ...data, ...newField }
        });
        setErrorOnForm(hasErrorOnForm);
        if (!hasErrorOnForm) handleFormPost(data);

    };

    const typeOfCustomer = new Object();
    const fieldProperties = new Object();
    fieldProperties.Multiple = false;

    const Options = new Array();

    var Option = new Object();
    Option.Name = "PF";
    Option.Ordination = 0;
    Options.push(Option);

    Option = new Object();
    Option.Name = "PJ";
    Option.Ordination = 1;
    Options.push(Option);

    typeOfCustomer.Options = Options;
    typeOfCustomer.Field = fieldProperties;

    return (

        <form className={`form-content ${isIframe ? '--zero-padding --no-container' : ''}`}>

            
            { isFieldContact && <div className='form-content__item' >
                <label htmlFor='name' className='form-content__label'>Tipo de cliente*</label>
                <div className='form-content__desc'></div>
                {errorOnForm && contactType === 0 &&
                    <div className='form-content__validation'>Campo obrigatório não preenchido</div>
                }                
                <InputSelect field={typeOfCustomer} index={0} setValue={onTypeInputChange} />
            </div>
            }

            {ordinatedFields.map((field, index) => {

                const canShowSelect = field.Options.length > 0 || (field.Field.TypeId === 7 && field.Field.SecondaryEntityId === 25);
                const fieldValue = formPayload[index] ? formPayload[index][field.ObjectKey] : '';

                //console.log('field.EntityId', field.ImportationTemplateEntityId);
                
                if (contactType == 1 && field.ImportationTemplateEntityId == 1 ) {
                    field.mustShowField = true;
                } else if (contactType == 2 && field.ImportationTemplateEntityId == 2) {
                    field.mustShowField = true;
                } else {
                    field.mustShowField = false;
                }

                if (field.mustShowField) {
                    return (
                        <div className='form-content__item' key={index}>
                            <label htmlFor='name' className='form-content__label'>{field.Label}{field.Required && '*'}</label>
                            <div className='form-content__desc'>{field.Description}</div>
                            {errorOnForm && checkEmptyFieldValue(formPayload[index][field.ObjectKey]) && field.Required &&
                                <div className='form-content__validation'>Campo obrigatório não preenchido</div>
                            }
                            {canShowSelect
                                ? <InputSelect field={field} index={index} setValue={onInputChange} />
                                : <>{getInputType(fieldValue, onInputChange, field, index, phoneTypes)}</>
                            }
                        </div>
                    )
                }else if(field.ImportationTemplateEntityId != 1 && field.ImportationTemplateEntityId != 2) {
                    //field.Required = false;
                    return (
                        <div className='form-content__item' key={index}>
                            <label htmlFor='name' className='form-content__label'>{field.Label}{field.Required && '*'}</label>
                            <div className='form-content__desc'>{field.Description}</div>
                            {errorOnForm && checkEmptyFieldValue(formPayload[index][field.ObjectKey]) && field.Required &&
                                <div className='form-content__validation'>Campo obrigatório não preenchido</div>
                            }
                            {canShowSelect
                                ? <InputSelect field={field} index={index} setValue={onInputChange} />
                                : <>{getInputType(fieldValue, onInputChange, field, index, phoneTypes)}</>
                            }
                        </div>
                    )
                }


            })}
            <div className='form-content__item'>
                <button onClick={onClickSubmit}
                    disabled={loadingFormSend || formSent}
                    className={`form-content__button ${loadingFormSend || formSent ? '--disabled' : ''}`}
                >
                    {loadingFormSend ? <LoadingDots /> : (formSent ? 'Formulário enviado com sucesso' : 'Enviar')}
                </button>
                {hasRequired && <p className='form-content__desc'>* campo obrigatório</p>}
            </div>
        </form>
    )
}