import React, { useCallback, useEffect, useState } from 'react';
import invert from 'invert-color';
import queryString from 'query-string';
import { FormContent } from './components/FormContent/FormContent';
import { FormInfo } from './components/FormInfo/FormInfo';
import { PloomesFloat } from './components/PloomesFloat/PloomesFloat';
import { Loading } from './components/Loading/Loading';
import { formGet, formPost } from './utils/service';
import { Toaster } from './components/Toaster/Toaster';
import './PloomesExternalForm.css';

const PloomesExternalForm = () => {

  const [loadingFormInfo, setLoadingFormInfo] = useState(true);
  const [loadingFormSend, setLoadingFormSend] = useState(false);
  const [canLoadForm, setCanLoadForm] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [fields, setFields] = useState([]);
  const [phoneTypes, setPhoneTypes] = useState([{ "Id": 1, "Name": "Comercial" }, { "Id": 2, "Name": "Celular" }, { "Id": 3, "Name": "Residencial" }, { "Id": 4, "Name": "Fax" }, { "Id": 5, "Name": "Outros" }]);
  const [info, setInfo] = useState({ name: '', description: '', avatarUrl: '' });
  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');
  const [toasterType, setToasterType] = useState('');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    const queryObject = queryString.parse(window.location.search);
    setIsIframe(queryObject.iframe ? true : false);
  }, []);

  const handleFormGet = useCallback(() => {
    formGet()
      .then(response => {
        setInfo({
          name: response.Name,
          description: response.Description,
          avatarUrl: response.AvatarUrl
        });
        setFields(response.Fields);
        setBgColor(response.Color ? response.Color : '#ffffff');
        setPhoneTypes(response.PhoneTypes);
      })
      .catch(console.log)
      .finally(() => {
        setLoadingFormInfo(false);
      });
  }, [setInfo, setFields, setLoadingFormInfo, setBgColor]);

  useEffect(() => {
    if (canLoadForm)  handleFormGet();
  }, [canLoadForm]);

  const handleFormPost = useCallback(data => {
    setLoadingFormSend(true);
    formPost(data)
      .then(response => {
        setToasterMessage('Formulário enviado com sucesso');
        setToasterType('SUCCESS');
          setFormSent(true);

          window.location.href = "https://agrisk.agr.br/";
      })
      .catch(e => {
        setToasterMessage('Erro ao enviar o formulário');
        setToasterType('ERROR');
      })
      .finally(() => {
        setLoadingFormSend(false);
        setShowToaster(true);
        setTimeout(() => {
          setShowToaster(false);
        }, 5000);
      });
  }, []);

  return (
    <div className={`ploomes-external-form ${isIframe ? '--zero-padding' : ''}`} style={{backgroundColor: !isIframe ? bgColor : 'transparent'}}>
      <Loading setCanLoadForm={setCanLoadForm} active={loadingFormInfo} isIframe={isIframe} />
      {fields.length > 0 &&
        <>
          {!isIframe && <FormInfo info={info} textColor={invert(bgColor, {black: '#333', white: '#fff'})} />}
          <FormContent
            loadingFormSend={loadingFormSend}
            formSent={formSent}
            fields={fields}
            handleFormPost={handleFormPost}
            setToasterType={setToasterType}
            setToasterMessage={setToasterMessage}
            setShowToaster={setShowToaster}
            isIframe={isIframe}
            phoneTypes={phoneTypes}
          />
        </>
      }
      {fields.length === 0 && !loadingFormInfo &&
        <h2 className='ploomes-external-form__not-found'>Não foi possível localizar um formulário</h2>
      }
      {!isIframe && <PloomesFloat />}
      <Toaster show={showToaster} message={toasterMessage} type={toasterType} />
    </div>
  );
}

export default PloomesExternalForm;
