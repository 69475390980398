import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader'
import './index.css';
import PloomesExternalForm from './PloomesExternalForm';

WebFont.load({
  google: {
      families: ["Open Sans:400,600,700", "Source Sans Pro:400,600,700"]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <PloomesExternalForm />
  </React.StrictMode>,
  document.getElementById('root')
);

