import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import './inputDateTime.css';

export const InputDateTime = props => {
  const {
    type,
    setValue,
    field,
    index,
  } = props;

  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    registerLocale('pt-BR', ptBR);
  }, []);

  useEffect(() => {
    let date = ''
    switch (type) {
      case 'date':
      default:
        if (selectedDate?.target?.value) {
          date = new Date(selectedDate.target.value.replace(/-/g, '\/'));
          date = moment(date).format('YYYY-MM-DD');
        }
        break;
      case 'time':
        if (selectedDate) {
          date = new Date(selectedDate).toISOString();
          date = moment(selectedDate).format();
          date = '1970-01-01T' + date.substring(
            date.lastIndexOf("T") + 1,
            date.length
          )
        }
        break;
    }
    setValue(date, index, field.ObjectKey, field.Required);
  }, [selectedDate]);

  switch (type) {
    case 'date':
    default:
      return <input className="form-content__input" type="date" onChange={setSelectedDate} />
    case 'time':
      return (
        <DatePicker
          selected={selectedDate}
          onChange={setSelectedDate}
          locale='pt-BR'
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Hora"
          dateFormat="HH:mm"
        />
      );
  }

}

InputDateTime.propTypes = {
  type: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
